import React from 'react'
import styled from 'styled-components'

import arrowDown from '../../../assets/static/service/child/arrow-down.svg'
import arrowLeft from '../../../assets/static/service/child/arrow-left.svg'
import arrowRight from '../../../assets/static/service/child/arrow-right.svg'
import Step1 from '../../../assets/static/service/child/SealantsStep1.svg'
import Step2 from '../../../assets/static/service/child/SealantsStep2.svg'
import Step3 from '../../../assets/static/service/child/SealantsStep3.svg'
import Step4 from '../../../assets/static/service/child/SealantsStep4.svg'
import Step5 from '../../../assets/static/service/child/SealantsStep5.svg'
import Step6 from '../../../assets/static/service/child/SealantsStep6.svg'
import { Primary, Secondary, White } from '../../../constants/newColor'
import { viewport } from '../../../constants/viewport'

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const ItemSection = styled.div`
  flex: 1;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
const MiddleSection = styled.div`
  display: flex;
  padding: 10px 80px;
  flex-direction: row;
  justify-content: space-between;
`
const LowerSection = styled.div`
  flex: 1;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`
const Title = styled.div`
  color: ${Primary};
  width: 70%;
  padding-right: 20px;
  text-align: right;
  font-size: 24px;
  @media (max-width: ${viewport.tablet - 1}px) {
    margin: 0 auto;
    font-size: 20px;
  }
`
const ItemWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`
const ItemIcon = styled.img`
  width: 94px;
  margin: 0 auto;
`
const ContentWrapper = styled.h3`
  padding: 0 10px;
  font-size: 15px;
  text-align: center;
  color: ${Secondary};
`
const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 85%;
    flex-direction: column;
  }
`
const MobileItemSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const MobileItemWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
`
const MobileNumber = styled.div`
  color: ${White};
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  background-color: ${Primary};
  margin-bottom: 10px;
`
const ArrowImage = styled.img`
  margin: 0 auto;
  width: 24px;
`
const ArrowDownImage = styled.img`
  margin-right: 10%;
  width: 24px;
  align-self: end;
`
const SealantsSection = () => {
  return (
    <>
      <DesktopWrapper>
        <ItemSection>
          <ItemWrapper>
            <ItemIcon src={Step1} alt='清潔牙齒' />
            <ContentWrapper>1. 清潔牙齒</ContentWrapper>
          </ItemWrapper>
          <ArrowImage src={arrowRight} alt='' />
          <ItemWrapper>
            <ItemIcon src={Step2} alt='用酸蝕讓牙齒表面輕微脫鈣' />
            <ContentWrapper>2. 用酸蝕讓牙齒表面輕微脫鈣</ContentWrapper>
          </ItemWrapper>
          <ArrowImage src={arrowRight} alt='' />
          <ItemWrapper>
            <ItemIcon src={Step3} alt='使用吸唾管將酸蝕清除乾淨，並將齒面擦乾' />
            <ContentWrapper>3. 使用吸唾管將酸蝕清除乾淨，並將齒面擦乾</ContentWrapper>
          </ItemWrapper>
        </ItemSection>
        <MiddleSection>
          <Title>溝隙封填怎麼做？</Title>
          <ArrowDownImage src={arrowDown} alt='' />
        </MiddleSection>
        <LowerSection>
          <ItemWrapper>
            <ItemIcon src={Step4} alt='使用牙科樹酯將窩溝封填起來' />
            <ContentWrapper>4. 使用牙科樹酯將窩溝封填起來</ContentWrapper>
          </ItemWrapper>
          <ArrowImage src={arrowLeft} alt='' />
          <ItemWrapper>
            <ItemIcon src={Step5} alt='照光待樹酯硬化' />
            <ContentWrapper>5. 照光待樹酯硬化</ContentWrapper>
          </ItemWrapper>
          <ArrowImage src={arrowLeft} alt='' />
          <ItemWrapper>
            <ItemIcon src={Step6} alt='檢查是否硬化成功就完成嘍！' />
            <ContentWrapper>6. 檢查是否硬化成功就完成嘍！</ContentWrapper>
          </ItemWrapper>
        </LowerSection>
      </DesktopWrapper>
      <MobileWrapper>
        <Title>溝隙封填怎麼做？</Title>
        <MobileItemSection>
          <MobileItemWrapper>
            <ItemIcon src={Step1} alt='清潔牙齒' />
            <MobileNumber>1</MobileNumber>
            <ContentWrapper>清潔牙齒</ContentWrapper>
          </MobileItemWrapper>
          <MobileItemWrapper>
            <ItemIcon src={Step2} alt='用酸蝕讓牙齒表面輕微脫鈣' />
            <MobileNumber>2</MobileNumber>
            <ContentWrapper>用酸蝕讓牙齒表面輕微脫鈣</ContentWrapper>
          </MobileItemWrapper>
        </MobileItemSection>
        <MobileItemSection>
          <MobileItemWrapper>
            <ItemIcon src={Step3} alt='使用吸唾管將酸蝕清除乾淨，並將齒面擦乾' />
            <MobileNumber>3</MobileNumber>
            <ContentWrapper>使用吸唾管將酸蝕清除乾淨，並將齒面擦乾</ContentWrapper>
          </MobileItemWrapper>
          <MobileItemWrapper>
            <ItemIcon src={Step4} alt='使用牙科樹酯將窩溝封填起來' />
            <MobileNumber>4</MobileNumber>
            <ContentWrapper>使用牙科樹酯將窩溝封填起來</ContentWrapper>
          </MobileItemWrapper>
        </MobileItemSection>
        <MobileItemSection>
          <MobileItemWrapper>
            <ItemIcon src={Step5} alt='照光待樹酯硬化' />
            <MobileNumber>5</MobileNumber>
            <ContentWrapper>照光待樹酯硬化</ContentWrapper>
          </MobileItemWrapper>
          <MobileItemWrapper>
            <ItemIcon src={Step6} alt='檢查是否硬化成功就完成嘍！' />
            <MobileNumber>6</MobileNumber>
            <ContentWrapper>檢查是否硬化成功就完成嘍！</ContentWrapper>
          </MobileItemWrapper>
        </MobileItemSection>
      </MobileWrapper>
    </>
  )
}

export default SealantsSection

import React from 'react'
import styled from 'styled-components'

import arrowDown from '../../../assets/static/service/child/arrow-down.svg'
import arrowRight from '../../../assets/static/service/child/arrow-right.svg'
import Step1 from '../../../assets/static/service/child/step1.svg'
import Step2 from '../../../assets/static/service/child/step2.svg'
import Step3 from '../../../assets/static/service/child/step3.svg'
import Step4 from '../../../assets/static/service/child/step4.svg'
import Step5 from '../../../assets/static/service/child/step5.svg'
import { Primary } from '../../../constants/newColor'
import { viewport } from '../../../constants/viewport'

const DesktopWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 311px;
    flex-direction: column;
  }
`
const DesktopItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileItemWrapper = styled.div`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 38px;
  }
`
const InfoWrapper = styled.div`
  width: 145px;
  padding: 22px 0px 22px 22px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
const ItemIcon = styled.img`
  width: 94px;
  margin: 0 auto;
`
const ArrowImage = styled.img`
  margin: 0 auto;
  :last-child {
    width: 0;
  }
`
const ItemTitleWrapper = styled.h3`
  color: ${Primary};
  font-size: 15px;
  margin: 0 auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    margin: 0;
  }
`
const ItemContentWrapper = styled.div`
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    margin: 5px 0 0 auto;
    text-align: right;
  }
`

interface FluorideType {
  title: string
  content: string
  icon: any
}

const FluorideInfo: FluorideType[] = [
  {
    title: 'Step 1.',
    content: '清潔牙齒表面',
    icon: Step1
  },
  {
    title: 'Step 2.',
    content: '吹乾牙齒上的水珠',
    icon: Step2
  },
  {
    title: 'Step 3.',
    content: '牙齒周圍塗氟',
    icon: Step3
  },
  {
    title: 'Step 4.',
    content: '靜置半小時待氟固化形成保護膜',
    icon: Step4
  },
  {
    title: 'Step 5.',
    content: '定期塗氟檢查',
    icon: Step5
  }
]

const FluorideItem = (props: FluorideType) => {
  const { title, content, icon } = props
  return (
    <>
      <DesktopItemWrapper>
        <ItemTitleWrapper>{title}</ItemTitleWrapper>
        <ItemIcon src={icon} alt={content} />
        <ItemContentWrapper>{content}</ItemContentWrapper>
      </DesktopItemWrapper>
      <MobileItemWrapper>
        <ItemIcon src={icon} alt={content} />
        <InfoWrapper>
          <ItemTitleWrapper>{title}</ItemTitleWrapper>
          <ItemContentWrapper>{content}</ItemContentWrapper>
        </InfoWrapper>
      </MobileItemWrapper>
    </>
  )
}

const FluorideSection = () => {
  return (
    <>
      <DesktopWrapper>
        {FluorideInfo.map(fluorideInfo => (
          <>
            <FluorideItem title={fluorideInfo.title} icon={fluorideInfo.icon} content={fluorideInfo.content} />
            <ArrowImage src={arrowRight} alt='' />
          </>
        ))}
      </DesktopWrapper>
      <MobileWrapper>
        {FluorideInfo.map(fluorideInfo => (
          <>
            <FluorideItem title={fluorideInfo.title} icon={fluorideInfo.icon} content={fluorideInfo.content} />
            <ArrowImage src={arrowDown} alt='' />
          </>
        ))}
      </MobileWrapper>
    </>
  )
}

export default FluorideSection
